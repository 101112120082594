import { mapGetters } from "vuex"

import is_data_table_source from "@/nibnut/mixins/IsDataTableSource"

import VueApexCharts from "vue-apexcharts"
window.Apex.colors = [
    "#2CB0DC", "#C642DB", "#DB7A16", "#84DB21", "#375CDB",
    "#DB4269", "#8F8715", "#71925F", "#D486B2", "#765F9B",
    "#F4C550", "#9C3821", "#718C9C", "#9A5B42", "#8A776D",
    "#692B56", "#CEC842", "#DBA716", "#4D494A", "#000000"
]

const is_report = {
    mixins: [is_data_table_source],
    components: {
        Apexchart: VueApexCharts
    },
    computed: {
        ...mapGetters(["entity_records", "entity_record"]),
        rows () {
            return this.records
        }
    },
    props: {
        records: {
            type: Array,
            default () {
                return []
            }
        }
    }
}

export default is_report
