<template>
    <div v-if="rows.length">
        <apexchart
            :height="160"
            type="line"
            :options="chart_options"
            :series="chart_data"
        />

        <h5>{{ rows[rows.length - 1].reported_at | nibnut.date("MMM dd") }}</h5>
        <data-table
            id="bitfender-latest-threats"
            :columns="visible_columns"
            :rows="filtered_rows"
            :search="state.search"
            :total="state.total"
            :found="state.found"
            :current-page="state.page"
            :total-pages="nb_pages"
            :can-add="false"
            :show-head="false"
            :show-totals="false"
            :searchable="false"
            :clickable="false"
            @sort="sort_by"
            @page="goto_page"
        >
            <template
                v-slot:summary="{ row }"
            >
                <h6>{{ row.name }}</h6>
                <div
                    v-if="threat_count(row)"
                    class="text-error"
                >
                    {{ $root.translate("1 file:::{n} files", { n: threat_count(row) }, threat_count(row)) }}
                </div>
                <div
                    v-else
                    class="text-success"
                >
                    {{ $root.translate("None") }}
                </div>
            </template>
            <template
                v-slot:tbody="{ row, field }"
            >
                <span v-if="field === 'threats'">
                    <span
                        v-if="threat_count(row)"
                        class="text-error"
                    >
                        {{ $root.translate("1 file:::{n} files", { n: threat_count(row) }, threat_count(row)) }}
                    </span>
                    <span
                        v-else
                        class="text-success"
                    >
                        {{ $root.translate("None") }}
                    </span>
                </span>
                <span v-else>{{ row[field] }}</span>
            </template>
        </data-table>
    </div>
</template>

<script>
import orderBy from "lodash/orderBy"
import parseISO from "date-fns/parseISO"
import format from "date-fns/format"

import is_report from "./IsReport"

export default {
    name: "BitdefenderReport",
    mixins: [is_report],
    methods: {
        threats (row) {
            return this.entity_records("bitdefender_threat").filter(threat => threat.bitdefender_endpoint_id === row.id)
        },
        threat_count (row) {
            return this.threats(row).length
        },
        sort_rows (rows) {
            if(this.state.sort_by) {
                return orderBy(rows, (row) => {
                    if(this.state.sort_by === "threats") return this.threat_count(row)
                    if(!row[this.state.sort_by]) {
                        if(this.state.sort_dir === "asc") return "999999999"
                        return "000000000"
                    }
                    return row[this.state.sort_by]
                }, this.state.sort_dir)
            }
            return rows
        }
    },
    computed: {
        state_identifier () {
            return "bitdefender-report"
        },
        chart_raw_data () {
            const data = {}
            this.rows.forEach(row => {
                this.threats(row).forEach(threat => {
                    const date = parseISO(threat.reported_at)
                    const index = format(date, "yyyy-MM-dd")
                    if(!data[index]) data[index] = { category: format(date, "MM-dd"), count: 0 }
                    data[index].count++
                })
            })

            const dates = Object.keys(data)
            dates.sort()
            const raw_data = {}
            dates.forEach(date => {
                raw_data[data[date].category] = data[date].count
            })

            return raw_data
        },
        chart_options () {
            const categories = Object.keys(this.chart_raw_data)

            return {
                chart: {
                    id: "line-bitfender",
                    height: 160,
                    type: "line",
                    toolbar: {
                        show: false
                    }
                },
                grid: {
                    xaxis: {
                        lines: {
                            show: false
                        }
                    }
                },
                dataLabels: {
                    enabled: false
                },
                xaxis: {
                    type: "dateTime",
                    tickAmount: 15,
                    categories
                },
                yaxis: {
                    forceNiceScale: true,
                    labels: {
                        formatter: (value) => {
                            return this.$numeral(value).format("0,0")
                        }
                    }
                }
            }
        },
        chart_data () {
            return [
                {
                    name: this.$root.translate("Threats"),
                    data: Object.values(this.chart_raw_data)
                }
            ]
        }
    },
    data () {
        return {
            columns: {
                name: { label: "Computer", sort: false, cell_class: "no-wrap" },
                threats: { label: "Quarantined", sort: false }
            },

            default_state: {
                per_page: 5,
                page: 1,
                sort_by: "threats",
                sort_dir: "desc",
                filter_on: null,
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0
            }
        }
    }
}
</script>
